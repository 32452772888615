import { message, Form, Image, Select, Button, Tooltip } from "antd";
import {
  searchCompany,
  entityDataSearch,
  entityFilterSearch,
} from "api/search";
import DataTable from "components/DataTable";
import { getIdColumn } from "components/DataTable/Columns";
import { DEFAULT_DATE_FORMAT, ENTITY_TYPES } from "constants";
import moment from "moment";
import { getSelectedWorkspaceProfileId } from "utils";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Searchbar from "../Commons/Searchbar";
import SideFilters from "../Commons/SideFilters";
import FilterFieldsContainer from "../Commons/SideFilters/Fields/FilterFieldsContainer";
import FilterDropdown, {
  FILTER_DROPDOWN_SUFFIX,
} from "../Commons/SideFilters/Fields/FilterDropdown";
import "./index.scss";
import { SHOULD_SEARCH_ON_PAGE_LOAD } from "constants";
import { isEmpty } from "lodash";
import { truncateText } from "utils";

function CompaniesSearch() {
  const companyColumns = [
    {
      title: "COMPANY ID",
      dataIndex: "revcloud_company_id",
    },
    {
      title: "SOURCE",
      dataIndex: "source",
    },
    {
      title: "COMPANY NAME",
      dataIndex: "name",
      // render: (text) => <a className="nameValue">{text}</a>,
    },
    {
      title: "COMPANY WEBSITE",
      dataIndex: "website",
      render: (text) => (
        <Tooltip title={text}>
          <a href={text} target="_blank" rel="noopener noreferrer">
            {truncateText(18, text)}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "LINKEDIN URL",
      dataIndex: "linkedin_url",
      render: (text) => (
        <Tooltip title={text}>
          <a href={text} target="_blank" rel="noopener noreferrer">
            {truncateText(18, text)}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "SIZE",
      dataIndex: "size",
      width: 100,
    },
    {
      title: "ACCURATE TO DATE",
      dataIndex: "accurate_to_date",
    },
  ];
  const [isBeginingSearch, setIsBeginingSearch] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? false : true
  );
  const [searchedText, setSearchedText] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? "" : null
  );

  const [searchedFilters, setSearchedFilters] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [idDropdownValues, setIdDropdownValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [resizingResults, setResizingResults] = useState(false);
  const userSession = useSelector((state) => state.authReducer).userSession;
  const { accountId, userId } = useSelector(
    (state) => state.authReducer
  ).userSession;
  const shouldFetchResults = useRef(SHOULD_SEARCH_ON_PAGE_LOAD ? true : false);
  const [form] = Form.useForm();
  let cols = companyColumns;

  useEffect(() => {
    if (shouldFetchResults.current) {
      getSearchResults();
    }
  }, [searchedText]);

  useEffect(() => {
    setResizingResults(true);
    setTimeout(() => {
      setResizingResults(false);
    }, 100);
  }, [showFilters]);

  const idClicked = (id) => {
    form.setFieldsValue({ revcloud_company_id: [id] });
    setSearchedFilters({ revcloud_company_id: [id] });
    setShowFilters(true);
  };

  const getSearchResults = () => {
    const filters = {};
    Object.keys(searchedFilters).forEach((filter) => {
      let filterValue = searchedFilters[filter];
      if (moment.isMoment(filterValue)) {
        filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
      } else if (["string", "number"].includes(typeof filterValue)) {
        filterValue = [filterValue];
      } else {
        filterValue = filterValue || [];
      }
      filters[filter] = filterValue.join(",");
    });
    const payload = {
      body: {
        ...filters,
      },
      params: {
        userId,
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        page: 1,
        limit: 1000,
      },
    };
    const searchPayload = {
      body: {
        ...filters,
      },
      params: {
        userId,
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        page: 1,
        limit: 1000,
        searchTerm: searchedText,
        entityTypeId: ENTITY_TYPES.COMPANY,
      },
    };
    if (searchedText === "") {
      setLoading(true);
      searchCompany(payload)
        .then((res) => {
          const formattedData = res?.data?.data?.data
            ? res.data.data.data.map((el) => {
                el.last_activity = moment(el.last_activity)
                  .utc()
                  .format(DEFAULT_DATE_FORMAT);
                return el;
              })
            : [];

          setSearchResults(formattedData);
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (searchedText !== "") {
      setLoading(true);
      entityDataSearch(searchPayload)
        .then((res) => {
          setSearchResults(res?.data?.data?.data);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onApplyFilters = (changedFields) => {
    const updatedFilters = { ...searchedFilters, ...form.getFieldsValue() };

    setSearchedFilters(updatedFilters);

    const hasDataInFilters = Object.values(updatedFilters).some(
      (value) => value && value.length > 0
    );

    if (hasDataInFilters) {
      const filters = {};
      Object.keys(updatedFilters).forEach((filter) => {
        let filterValue = updatedFilters[filter];

        if (moment.isMoment(filterValue)) {
          filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
        } else if (["string", "number"].includes(typeof filterValue)) {
          filterValue = [filterValue];
        } else {
          filterValue = filterValue || [];
        }

        filters[filter] = filterValue;
      });

      const searchPayload = {
        body: {
          filters: {
            ...filters,
            company: searchedText ? [searchedText.trim()] : [],
          },
        },
        params: {
          userId,
          profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
          entityTypeId: ENTITY_TYPES.COMPANY,
        },
      };

      setLoading(true);
      entityFilterSearch(searchPayload)
        .then((res) => {
          const formattedData = Array.isArray(res?.data?.data)
            ? res.data.data.map((el) => {
                el.last_activity = moment(el.last_activity)
                  .utc()
                  .format(DEFAULT_DATE_FORMAT);
                return el;
              })
            : [];

          setSearchResults(formattedData);
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getSearchResults();
    }
  };

  const onResetFields = (fields) => {
    form.resetFields(fields);
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    const fieldValues = form.getFieldsValue();
    fields.forEach((field) => {
      fieldValues[field] = "";
    });
    setSearchedFilters(fieldValues);
    getSearchResults();
  };

  const handleSelectIdDropdown = (id) => {
    const selectedId = id;
    const existingIds = idDropdownValues;
    console.log("Existing Ids state:", existingIds);

    const updatedIds = existingIds.push(selectedId);
    setIdDropdownValues(updatedIds);

    console.log("Updated Ids state:", idDropdownValues);
  };

  const filterFields = [
    <FilterFieldsContainer
      label="Source"
      onClear={() => {
        onResetFields(["source"]);
      }}
    >
      <FilterDropdown
        name="source"
        placeholder="Enter source name here…"
        selectMode="tags"
        // options={filterOptions.sources}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Profile"
      onClear={() => {
        onResetFields(["companyWebsite", "sourceUrl"]);
      }}
    >
      <FilterDropdown
        name="companyWebsite"
        selectMode="tags"
        placeholder="Company’s website…"
        // options={filterOptions.companyWebsite}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.LINK}
        onChange={onApplyFilters}
      />
      <FilterDropdown
        name="sourceUrl"
        selectMode="tags"
        placeholder="Source URL…"
        // options={filterOptions.sourceUrl}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.LINK}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Company ID"
      onClear={() => {
        onResetFields(["revcloud_company_id"]);
      }}
    >
      <FilterDropdown
        name="revcloud_company_id"
        selectMode="tags"
        placeholder="Company ID…"
        // options={filterOptions.revcloud_company_id}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.ID}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Company attributes"
      onClear={() => {
        onResetFields(["size", "country"]);
      }}
    >
      <FilterDropdown
        name="size"
        selectMode="tags"
        placeholder="Size"
        // options={filterOptions.size}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.SIZE}
        onChange={onApplyFilters}
      />
      {/* <FilterDropdown
        name="country"
        selectMode="tags"
        placeholder="Country"
        options={filterOptions.country}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.COUNTRY}
        onChange={onApplyFilters}
      /> */}
    </FilterFieldsContainer>,
  ];

  return (
    <div className="companiesSearch">
      {/* <div className="companiesSearch__saveContainer">
        <Button className="companiesSearch__saveContainer__button">
          Save Search
        </Button>
      </div> */}
      <Searchbar
        placeholder="Search..."
        filtersVisible={showFilters}
        onCloseBtnClicked={() => getSearchResults}
        onFiltersVisibilityChange={setShowFilters}
        onSubmitSearchText={(searchedText) => {
          shouldFetchResults.current = true;
          setIsBeginingSearch(false);
          setSearchedText(searchedText);
        }}
        filtersApplied={
          !isEmpty(
            Object.values(searchedFilters).filter((value) => {
              return !isEmpty(value);
            })
          )
        }
      />
      <div className="companiesSearch__filterAndResults">
        <>
          {showFilters && (
            <div className="companiesSearch__filterAndResults__filters">
              <Form form={form}>
                <SideFilters
                  filterFields={filterFields}
                  onClose={() => {
                    setShowFilters(false);
                  }}
                  onReset={() => {
                    form.resetFields();
                    shouldFetchResults.current = true;
                    setIsBeginingSearch(false);
                    setSearchedFilters({});
                    getSearchResults();
                  }}
                />
              </Form>
            </div>
          )}
          {!isBeginingSearch && !resizingResults && (
            <div className="companiesSearch__filterAndResults__results">
              <DataTable
                entityTypeId={ENTITY_TYPES.COMPANY}
                loading={loading}
                columns={cols}
                dataSource={searchResults}
                // detailsKey="company_name"
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
}
export default CompaniesSearch;
