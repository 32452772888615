import { useState, useEffect } from "react";
import {
  List,
  Card,
  Image,
  Spin,
  Select,
  Button,
  Checkbox,
  Avatar,
  notification,
  Modal,
  Input,
  Radio,
} from "antd";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import {
  getDestinationExtensions,
  createDestinationExtension,
} from "api/Datasets";

const { Option } = Select;

function DesktopSelectSource({
  handleSourceSelection,
  handleDestinationValue,
  destinations,
  destinationsLoader,
}) {
  const navigate = useNavigate();
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [selectedDestinationDetails, setSelectedDestinationDetails] = useState(
    {}
  );
  // console.log("seeeee", selectedDestinationDetails);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newEntityName, setNewEntityName] = useState("");
  const [extensions, setExtensions] = useState([]);
  const [selectedExtension, setSelectedExtension] = useState(null);
  const [loadingExtensions, setLoadingExtensions] = useState(false);
  useEffect(() => {
    // Fetch extensions when the specific destination (ID 17) is selected
    if (selectedDestinationDetails?.source?.id == 1) {
      fetchExtensions();
      console.log("selectedDestinationDetails", selectedDestinationDetails);
    }
  }, [selectedDestinations]);

  const fetchExtensions = async () => {
    setLoadingExtensions(true); // Set loading state before API call
    try {
      const response = await getDestinationExtensions({
        connectionId: selectedDestinationDetails.client_source_id, // Assuming ID 17 is the first selected
        connectionName: selectedDestinationDetails.unique_name, // Replace with the actual name
      });
      setExtensions(response?.data?.data?.dataExtensions?.items || []);
    } catch (err) {
      notification.error({
        message: "Error fetching extensions",
        description: err.message,
      });
    } finally {
      setLoadingExtensions(false);
    }
  };

  const handleRadioChange = (id) => {
    // Update the selected destination to only have the currently selected ID
    setSelectedDestinations([id]); // Radio will only allow one selected, so use an array with a single element
    // Fetch and set details for the selected destination
    const selectedDetails = destinations.find(
      (dest) => dest.client_source_id === id
    );
    if (selectedDetails) {
      setSelectedDestinationDetails(selectedDetails); // Use object with single entry
      handleDestinationValue(selectedDetails); // Send selected destination details
    } else {
      setSelectedDestinationDetails({});
    }
  };

  const handleSelectChange = (value) => {
    if (value === "__create__") {
      setIsModalVisible(true);
    } else {
      const selected = extensions?.find((ext) => ext.id === value);
      if (selected) {
        setSelectedExtension(selected);
      }
    }
  };

  const createExtension = async () => {
    // console.log("sele", selectedDestinationDetails);
    try {
      const response = await createDestinationExtension({
        connectionId: selectedDestinationDetails.client_source_id, // Using client_source_id for connectionId
        connectionName: selectedDestinationDetails.unique_name, // Using connection_name from selectedDestinationDetails
        name: newEntityName, // New extension name
        categoryId: extensions[0]?.categoryId, // Assuming you want the categoryId of the selected extension
      });

      // Refresh extensions to include the newly created extension
      fetchExtensions();

      // Reset modal and input fields
      setNewEntityName("");
      setIsModalVisible(false);

      // Notify user of success
      notification.success({
        message: "Extension Created",
        description: "New extension has been created successfully.",
      });
    } catch (err) {
      // Notify user of error
      notification.error({
        message: "Error creating extension",
        description: err.message,
      });
    }
  };

  const handleSearch = (input, option) => {
    const searchInput = input.toLowerCase();
    const optionText = option.children.toLowerCase();
    return optionText.includes(searchInput);
  };
  const handleModalOk = () => {
    if (newEntityName) {
      createExtension();
    }
  };

  const handleModalCancel = () => {
    setNewEntityName("");
    setIsModalVisible(false);
  };

  const finalizeSelection = () => {
    handleSourceSelection(selectedDestinationDetails);
    handleDestinationValue(selectedDestinationDetails, selectedExtension);
  };

  const handleDisabledClick = () => {
    notification.warning({
      message: "Destination Required",
      description: "Please choose at least one destination.",
      placement: "topRight",
    });
  };

  const isSelectionMade = Object.values(selectedDestinationDetails).some(
    (subDest) => subDest.length > 0
  );

  const handleRedirect = () => {
    navigate("/dashboard/datasets/destination/catalogList");
  };

  return (
    <div className="desktop-select-destination">
      {destinationsLoader ? (
        <div className="loading-container">
          <Spin />
        </div>
      ) : (
        <div>
          <List
            dataSource={destinations}
            locale={{
              emptyText: (
                <>
                  <div>
                    No destinations found. Only configured destinations will be
                    displayed here.
                  </div>
                  <div>
                    <Button
                      type="primary"
                      onClick={handleRedirect}
                      className={"redirectBtn"}
                    >
                      Add Destination
                    </Button>
                  </div>
                </>
              ),
            }}
            renderItem={(item) => (
              <List.Item style={{ padding: 0, flexDirection: "column" }}>
                <Card
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    marginTop: "12px",
                    borderRadius: "16px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    minHeight: "40px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Radio
                      className="custom-radio"
                      checked={selectedDestinations.includes(
                        item.client_source_id
                      )}
                      onChange={() => handleRadioChange(item.client_source_id)}
                    />
                    <Image
                      src={item.source.icon_url}
                      width={30}
                      height={30}
                      style={{ marginLeft: "16px" }}
                      preview={false}
                    />
                    <div
                      style={{
                        marginLeft: "28px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: item.source.name
                          ? "flex-start"
                          : "center",
                        height: "100%",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontFamily: "Lato, sans-serif",
                          fontWeight: "501",
                          fontSize: "10.6pt",
                          color: "#000000",
                        }}
                      >
                        {item.name}
                      </p>
                      {item.source.name && (
                        <p
                          style={{
                            margin: 0,
                            fontFamily: "Lato, sans-serif",
                            fontWeight: "300",
                            fontSize: "9.8pt",
                            color: "#000000",
                          }}
                        >
                          {item.source.name}
                        </p>
                      )}
                    </div>
                  </div>
                </Card>

                {item.source.id === 1 &&
                  selectedDestinationDetails?.source?.id == 1 &&
                  item.client_source_id ===
                    selectedDestinationDetails.client_source_id && (
                    <>
                      <Select
                        showSearch={true}
                        filterOption={handleSearch}
                        placeholder="Select an existing extension or create a new one"
                        className="custom-select"
                        style={{
                          width: "100%",
                          marginTop: "12px",
                        }}
                        dropdownStyle={{ maxHeight: 200, overflowY: "auto" }}
                        onChange={handleSelectChange}
                        value={selectedExtension?.name || undefined}
                        dropdownRender={(menu) => (
                          <>
                            <div
                              className="create-new-extension"
                              onClick={() => setIsModalVisible(true)}
                            >
                              + Create New Extension
                            </div>
                            {menu}
                          </>
                        )}
                      >
                        {loadingExtensions ? (
                          <Option key="loading" disabled>
                            <Spin
                              size="small"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            />
                          </Option>
                        ) : (
                          extensions?.map((ext) => (
                            <Option key={ext.id} value={ext.id}>
                              {ext.name}
                            </Option>
                          ))
                        )}
                      </Select>

                      <Modal
                        title="Create New Extension"
                        visible={isModalVisible}
                        onOk={handleModalOk}
                        onCancel={handleModalCancel}
                        okText="Create"
                        className="custom-modal"
                      >
                        <Input
                          value={newEntityName}
                          onChange={(e) => setNewEntityName(e.target.value)}
                          placeholder="Enter the name of the extension"
                          className="custom-input"
                        />
                      </Modal>
                    </>
                  )}
              </List.Item>
            )}
          />
          {destinations.length == 0 ? null : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "12px",
                width: "100%",
              }}
            >
              <Button
                type="primary"
                className={isSelectionMade ? "saveButton" : "disabled"}
                onClick={() => {
                  // if (!isSelectionMade) {
                  //   handleDisabledClick();
                  // } else {
                  finalizeSelection();
                  // }
                }}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DesktopSelectSource;
