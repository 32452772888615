import {
  Collapse,
  Image,
  Card,
  Typography,
  Button,
  Input,
  Checkbox,
  Progress,
} from "antd";
import { useEffect, useState } from "react";
import { getPreviewSheetLink } from "api/Datasets";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { FileTextOutlined } from "@ant-design/icons";

const { Text, Link } = Typography;

function DesktopPreviewYourList({
  previewData,
  tempId,
  listId,
  handlePreviewSelection,

}) {
  const navigate = useNavigate();
  const [previewLink, setPreviewLink] = useState(null);
  const [progress, setProgress] = useState(0);
  const [hasRunOnce, setHasRunOnce] = useState(false);

  // useEffect(() => {
  //   // If it's the first time running the effect or if previewLink is null/undefined
  //   if (!hasRunOnce || previewLink === null || previewLink === undefined) {
  //     // Set the effect as having run at least once
  //     setHasRunOnce(true);

  //     // Define the interval function
  //     const interval = setInterval(() => {
  //       setProgress((prev) => (prev < 92 ? prev + 8 : prev + 1)); // Continue incrementing slowly after 90%
  //     }, 5000);

  //     // Define the payload for the API call
  //     const payload = {
  //       tmpId: tempId,
  //       listId: listId,
  //     };

  //     // Perform the API call
  //     getPreviewSheetLink(payload)
  //       .then((res) => {
  //         console.log('res123', res?.data)
  //         if (res?.data?.googleDriveLink) {
  //           console.log('res123', res?.data)
  //           clearInterval(interval);
  //           setProgress(100); // Immediately set to 100% on success
  //           setPreviewLink(res?.data?.googleDriveLink);
  //           handlePreviewSelection(true);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error("Error fetching data:", err);
  //         clearInterval(interval); // Clear interval on error
  //       });

  //     // Cleanup function to clear the interval
  //     return () => clearInterval(interval);
  //   }
  // }, [previewLink, !hasRunOnce]); // Dependency array to trigger the effect based on previewLink changes

  // Rest of your component code


  useEffect(() => {

    const interval = setInterval(() => {
      setProgress((prev) => (prev < 92 ? prev + 8 : prev + 1)); // Continue incrementing slowly after 90%
    }, 5000);

    // Define the payload for the API call
    const payload = {
      tmpId: tempId,
      listId: listId,
    };

    // Perform the API call
    getPreviewSheetLink(payload)
      .then((res) => {
        if (res?.data?.googleDriveLink) {
          clearInterval(interval);
          setProgress(100); // Immediately set to 100% on success
          setPreviewLink(res?.data?.googleDriveLink);
          handlePreviewSelection(true);
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        clearInterval(interval); // Clear interval on error
      });

    // Cleanup function to clear the interval
    return () => clearInterval(interval);

  }, []); // Dependency array to trigger the effect based on previewLink changes




  return (
    <div className="DesktopPreviewYourList">
      <Card className="DesktopPreviewYourList__customCardContent">
        {previewLink ? (
          <Link
            className="DesktopPreviewYourList__subHeading"
            href={previewLink}
            target="_blank"
          >
            {previewLink}
          </Link>
        ) : (
          <div className="no-data-message">
            <p>
              Your link is being prepared. It will be ready shortly. Please
              wait...
            </p>
            <Progress
              percent={progress}
              status={progress < 100 ? "active" : "normal"}
            />
          </div>
        )}
      </Card>
    </div>
  );
}
export default DesktopPreviewYourList;
