import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Space,
  Table,
  Tag,
  Typography,
  Avatar,
  Spin,
  Image,
  Select,
  Input,
  notification
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { SearchOutlined, DownloadOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { IconSalesforce, IconThreeDots } from "images";

const { Option } = Select;

const DatasetsDesktop = ({
  datasetsListData,
  loader,
  handlePageNumber,
  hasMore,
  downloadDatasetLink
}) => {
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchInput, setSearchInput] = useState("");

  const columns = [
    {
      title: <span className="bold-title">NAME, CREATED, TAGS</span>,
      dataIndex: "name",
      render: (text, record) => (
        <div className="datasetsListContainer__datasetInfo">
          <p className="datasetsListContainer__p">
            {updateAudienceName(record.dataset_name)}
          </p>
          <small className="datasetsListContainer__small">
            Created By {changetheDatasetName(record)}
          </small>
        </div>
      ),
      className: "table-row",
    },
    {
      title: <span className="bold-title">STATUS</span>,
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "Offline":
            color = "#cccccc";
            break;
          case "Active":
            color = "#84d830";
            break;
          case "Unavailable":
            color = "red";
            break;
          default:
            color = "default";
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Avatar
              size={10}
              shape="circle"
              style={{ backgroundColor: color, marginRight: "10px" }}
            />
            {status == "Active" ? "Live" : "Disabled"}
          </div>
        );
      },
      className: "table-row",
    },
    {
      title: <span className="bold-title">DESTINATION</span>,
      dataIndex: "destination",
      render: () => (
        <Space style={{ marginLeft: "25px" }}>
          <Image
            src={IconSalesforce}
            width={30}
            height={30}
            style={{ marginLeft: "4px" }}
            preview={false}
          />
        </Space>
      ),
      className: "table-row",
    },
    {
      title: <span className="bold-title">ACTION</span>,
      dataIndex: "action",
      render: (text, record) => (
        <Space style={{ marginLeft: "5px" }}>
          <Image src={IconThreeDots} preview={false} />
          <DownloadOutlined
            style={{
              fontSize: "20px", // Increase size
              cursor: "pointer", // Add pointer cursor
              color: "#0f0f60", // Optional: Add a color
              marginLeft: "15px", // Add spacing between icons
            }}
            onClick={() => downloadDataset(record?.dataset_id, record?.dataset_name)}
          />
        </Space>
      ),
      className: "table-row",
    },
  ];

  const handleSearch = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };


  const downloadDataset = async (datasetId, datasetName) => {
    try {
      // Call the API to get the signed URL
      const response = await downloadDatasetLink(datasetId);
      // Check if the response contains the signed URL
      if (response.data && !response.data.error) {
        const signedUrl = response.data.data.signedUrl;
        // Notify the user that the download is starting
        notification.success({
          message: "Download Started",
          description: `Your download for the dataset "${datasetName}" has started.`,
          duration: 3,
        });
        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = signedUrl;
        // Use the dataset name as the file name
        link.download = `${datasetName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Notify the user about the error
        notification.error({
          message: "Download Failed",
          description: response.data.message || "Could not generate the signed URL.",
          duration: 3,
        });
  
        console.error("Failed to generate the signed URL:", response.data.message);
      }
    } catch (error) {
      // Notify the user about the error
      notification.error({
        message: "Error",
        description: "An error occurred while downloading the dataset. Please try again.",
        duration: 3,
      });
  
      console.error("Error downloading dataset:", error);
    }
  };
  
  
  

  const filterByStatus = (data) => {
    switch (statusFilter) {
      case "all":
        return data; // Show all datasets
      case "enabled":
        return data.filter((item) => item.status == "Active"); // Show only enabled datasets
      case "disabled":
        return data.filter((item) => item.status == false); // Show only disabled datasets
      default:
        return data;
    }
  };

  const changetheDatasetName = (record) => {
    var name = "Smith";
    if (
      record.user_name === "Kimball Belmont Belmont" &&
      record.dataset_name === "launch"
    ) {
      name = "Ben Moore";
    } else if (
      record.user_name === "Kimball Belmont Belmont" &&
      record.dataset_name === "First Test Audience"
    ) {
      name = "Brycen Curry";
    } else if (
      record.user_name === "Muhammad ZamanTest" &&
      record.dataset_name === "New Dataset by MZ"
    ) {
      name = "Cohen Hurley";
    } else if (
      record.user_name === "Muhammad ZamanTest" &&
      record.dataset_name === "Test Dataset"
    ) {
      name = "Ronald Camacho";
    } else if (record.user_name === "Atul Gupta") {
      name = "Ronald Camacho";
    } else {
      name = record.user_name;
    }
    return name;
  };

  const updateAudienceName = (text) => {
    var name = "California Homeowners, 60+";
    if (text === "First Test Audience") {
      name = "California Homeowners, 60+";
    } else if (text === "launch") {
      name = "All-USA Homeowners, Good & Excellent Credit";
    } else if (text === "Test Dataset") {
      name = "All Homeowners in Top Area Codes";
    } else if (text === "New Dataset by MZ") {
      name = "Filled Home Services Lead in Past 3 Months";
    } else if (text === "Haq 4 Yrrrrr") {
      name = "Filled Home Services Lead in Past 3 Months";
    } else {
      name = text;
    }
    return name;
  };

  const handleStatusChange = (value) => {
    setStatusFilter(value);
  };

  const filteredData = filterByStatus(datasetsListData).filter((item) =>
    item.dataset_name.toLowerCase().includes(searchInput)
  );

  console.log('filteredData', filteredData)

  return (
    <div className="datasetsListContainer">
      <div className="datasetsListContainer__headerDiv">
        <div className="datasetsListContainer__header">
          <Typography.Text className="datasetsListContainer__h1Text">
            List of Audiences
          </Typography.Text>
          <p className="datasetsListContainer__h1Description">
            These are the audiences that you have created
          </p>
        </div>
        <Input
          className="datasetsListContainer__input"
          type="search"
          placeholder="Search for audiences by name"
          onChange={handleSearch}
          value={searchInput}
          prefix={<SearchOutlined style={{ color: "grey" }} />}
        />
        <Button
          className="datasetsListContainer__newDatasetBtn"
          onClick={() => navigate(`/dashboard/datasets/datasets/steps`)}
          icon={<PlusOutlined />}
        >
          New audience
        </Button>
      </div>
      <div className="datasetsListContainer__filter">
        <Select
          className="datasetsListContainer__status-filter"
          defaultValue="all"
          onChange={handleStatusChange}
          style={{ width: 165 }} // Adjust width as needed
        >
          <Option value="all">
            <span className="Status all-icon"></span> All
          </Option>
          <Option value="enabled">
            <span className="Status enabled-icon"></span> Live
          </Option>
          <Option value="disabled">
            <span className="Status disabled-icon"></span> Disabled
          </Option>
        </Select>
      </div>
      {loader ? (
        <div className="loading-container">
          <Spin />
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={filteredData} // Apply the filtered data here
            pagination={true}
          />
          {!hasMore && <div style={{ textAlign: "center" }}>No more data</div>}
        </>
      )}
    </div>
  );
};

export default DatasetsDesktop;
