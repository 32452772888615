import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Image, message, Spin, Alert } from "antd";
import "./index.scss";
import { createProfile, getAPIVersionData } from "api/Connectors";
import { getRequiredFields } from "store/modules/connector/action";
import Flex from "screens/Chat/Flex";
import { IconChevronLeft } from "images";
import { getCurrentDomainName, getSelectedWorkspaceProfileId } from "utils";

function ProcessingFields() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sourceId, sourceType } = useParams();
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const processingRequiredFields = useSelector(
    (state) => state?.connectorReducer?.requiredFields
  );
  const userSession = useSelector((state) => state.authReducer).userSession;
  const isLoader = useSelector((state) => state?.connectorReducer?.loading);
  const [spinning, setSpinning] = useState(false);
  const [componentFormError, setComponentFormError] = useState({});
  const [domain, setDomain] = useState("");
  const [currentDomain, setCurrentDomain] = useState(getCurrentDomainName());
  const [clientID, setClientID] = useState("");

  const [facebookAdsRedirectUrl, setFacebookAdsRedirectUrl] = useState(
    "https://palisade-app-qa.vercel.app/dashboard/sources/setup/facebookads/9"
  );
  // const [facebookAdsRedirectUrl, setFacebookAdsRedirectUrl] = useState(
  //   "https://localhost:3000/dashboard/sources/setup/facebookads/9"
  // );
  const [selectedApiVersion, setSelectedApiVersion] = useState("");
  const [apiVersions, setApiVersions] = useState([]);

  const ZendeskInfoMessage = (
    <div>
      <strong>
        To integrate your Zendesk account with {currentDomain} AI, follow these
        steps:
      </strong>
      <br />
      1. Log in to Zendesk, go to the Advance Features, Advance Settings,
      Channels, API.
      <br />
      2. Under OAuth Clients, select the OAuth client you created.
      <br />
      3. Replace your Redirect URLs with:
      <code>
        https://app.{currentDomain}.ai/dashboard/sources/setup/zendesk/7
      </code>
      <br />
      4. Copy the Unique Identifier and Client Secret provided in the OAuth
      client settings.
    </div>
  );
  const GoogleDriveInfoMessage = (
    <div>
      <strong>To grant access to the specified folder on Google Drive:</strong>
      <br />
      1. Navigate to the folder you want to share.
      <br />
      2. Click on the "Share" button.
      <br />
      3. In the "Share with others" window, paste the email address:
      <code>
        google-drive-connector@connectors-414911.iam.gserviceaccount.com
      </code>
      <br />
      4. Ensure the access permissions are set correctly (e.g., view).
      <br />
      5. Click on "Send" to grant access.
    </div>
  );
  const LeadsAPIInfoMessage = (
    <div>
      <strong>To grant access to the specified folder on Google Sheet:</strong>
      <br />
      1. Click on the "Share" button.
      <br />
      2. In the "Share with others" window, paste the email address:
      <code>
        create-metadata-sheets@palisade-metadata-sheets.iam.gserviceaccount.com
      </code>
      <br />
      3. Ensure the access permissions are set correctly (e.g., view).
      <br />
      4. Click on "Send" to grant access.
    </div>
  );
  const handleRedirectUrlForZendesk = () => {
    //Redirect user to Zendesk authorization endpoint
    window.location.href = `https://${domain}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri=https://app.${currentDomain}.ai/dashboard/sources/setup/zendesk/7&client_id=${clientID}&scope=read%20write`;
  };
  const handleRedirectUrlForGoogleAds = () => {
    //Redirect user to Zendesk authorization endpoint
    window.location.href = `https://accounts.google.com/o/oauth2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&response_type=code&prompt=consent&client_id=${clientID}&access_type=offline&redirect_uri=https://palisade-app-qa.vercel.app/dashboard/sources/setup/googleAds/13`;
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const FacebookInfoMessage = (
    <div>
      <strong>
        To integrate your Facebook Ads account with {currentDomain} AI, follow
        these steps:
      </strong>
      <br />
      1. Log in to Facebook Ads, go to the App Settings, Under the Basic
      Settings
      <br />
      2. Replace your Redirect URLs with:
      <code> {facebookAdsRedirectUrl}</code>
      <br />
      3. Copy the App Id and App Secret provided in the Basic settings.
    </div>
  );

  const handleRedirectUrlForFacebook = () => {
    const mClientId = clientID;
    const mApiVersion = selectedApiVersion;
    const redirectURI = facebookAdsRedirectUrl;
    const scope = "ads_management,ads_read,read_insights,business_management";
    const response_type = "code";
    const prompt = "consent";
    const state = "radom-string";

    // Redirect user to Facebook authorization endpoint
    https: window.location.href = `https://www.facebook.com/${mApiVersion}/dialog/oauth?scope=${scope}&response_type=${response_type}&prompt=${prompt}&client_id=${mClientId}&redirect_uri=${redirectURI}&state=${state}`;
  };

  const handleComponentFormChange = useCallback(
    (e, index) => {
      let data = [...processingRequiredFields];
      data[index][e.target.name] = e.target.value;
      if (e.target.name === "instanceUrl") {
        setDomain(e.target.value);
      }
      if (e.target.name === "clientId") {
        setClientID(e.target.value);
      }
      if (e.target.name === "apiVersion") {
        setSelectedApiVersion(e.target.value);
      }
    },

    [processingRequiredFields]
  );
  const validate = () => {
    const errors = {};
    processingRequiredFields.forEach((field) => {
      if (field.required && !field[field.name]) {
        errors[
          field.name
        ] = `Please fill out the ${field.heading.toLowerCase()} field!`;
      }
    });
    return errors;
  };

  const handleAuthentication = () => {
    if (sourceId === "7") {
      const formErrors = validate(processingRequiredFields);
      setComponentFormError(formErrors);
      if (Object.keys(formErrors).length === 0) {
        const requestBody = {};
        processingRequiredFields.forEach((field) => {
          if (field[field.name]) {
            requestBody[field.name] = field[field.name];
          }
        });
        requestBody.redirectUri = `https://app.${currentDomain}.ai/dashboard/sources/setup/zendesk/7`;
        requestBody.instanceUrl = `https://${domain}.zendesk.com`;
        requestBody.connectorId = parseInt(sourceId);
        sessionStorage.setItem("requestBody", JSON.stringify(requestBody));
        handleRedirectUrlForZendesk();
      }
    } else if (sourceId === "13") {
      const formErrors = validate(processingRequiredFields);
      setComponentFormError(formErrors);
      if (Object.keys(formErrors).length === 0) {
        const requestBody = {};
        processingRequiredFields.forEach((field) => {
          if (field[field.name]) {
            requestBody[field.name] = field[field.name];
          }
        });
        requestBody.redirectUri =
          "https://palisade-app-qa.vercel.app/dashboard/sources/setup/googleAds/13";
        requestBody.connectorId = parseInt(sourceId);
        sessionStorage.setItem("requestBody", JSON.stringify(requestBody));
        handleRedirectUrlForGoogleAds();
      }
    } else if (sourceId === "9") {
      const formErrors = validate(processingRequiredFields);
      setComponentFormError(formErrors);
      if (Object.keys(formErrors).length === 0) {
        const requestBody = {};
        processingRequiredFields.forEach((field) => {
          if (field[field.name]) {
            requestBody[field.name] = field[field.name];
          }
        });
        requestBody.redirectUri = facebookAdsRedirectUrl;
        requestBody.connectorId = parseInt(sourceId);
        sessionStorage.setItem("requestBody", JSON.stringify(requestBody));
        handleRedirectUrlForFacebook();
      }
    }
  };
  const onSubmit = async () => {
    const formErrors = validate(processingRequiredFields);
    setComponentFormError(formErrors);
    if (Object.keys(formErrors).length === 0) {
      const requestBody = {};
      processingRequiredFields.forEach((field) => {
        if (field[field.name]) {
          requestBody[field.name] = field[field.name];
        }
      });
      if (sourceId) {
        requestBody.connectorId = parseInt(sourceId);
      }
      requestBody.profileWorkspaceId =
        getSelectedWorkspaceProfileId(userSession);
      setSpinning(true);
      try {
        const res = await createProfile(requestBody);
        message.success(res?.data?.message);
        navigate(`/dashboard/datasets/sources`);
      } catch (err) {
        console.error("Error:", err);
      } finally {
        setSpinning(false);
      }
    }
  };

  useEffect(() => {
    dispatch(getRequiredFields(sourceId));
  }, [dispatch]);

  useEffect(() => {
    if (
      (sourceId === "7" || sourceId === "13" || sourceId === "9") &&
      code &&
      sessionStorage.getItem("requestBody")
    ) {
      const storedRequestBody = JSON.parse(
        sessionStorage.getItem("requestBody")
      );

      storedRequestBody.authCode = code;
      setSpinning(true);
      createProfile(storedRequestBody)
        .then((res) => {
          message.success(res?.data?.message);
          navigate(`/dashboard/datasets/sources`);
          sessionStorage.removeItem("requestBody");
        })
        .catch((err) => {})
        .finally(() => {
          setSpinning(false);
        });
    }
  }, [sourceId, code]);

  useEffect(async () => {
    if (sourceId === "13")
      try {
        const response = await getAPIVersionData(
          sourceType == "googleAds" ? "GoogleAds" : null
        );
        if (response.data.error === false) {
          setApiVersions(response.data.data);
        } else {
          console.error("Error fetching API versions:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching API versions:", error);
      }

    if (sourceId === "9")
      try {
        const response = await getAPIVersionData("FacebookAds");
        if (response.data.error === false) {
          setApiVersions(response.data.data);
        } else {
          console.error("Error fetching API versions:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching API versions:", error);
      }
  }, []);

  return (
    <>
      {/* <div className="Fixed">
        <div className="fixedBar">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span className="Title"> Connection Setup</span>
        </div>
      </div> */}
      {spinning || isLoader ? (
        <div className="loading-container">
          {spinning ? (
            "Please wait, we're setting up your connection"
          ) : (
            <Spin />
          )}
        </div>
      ) : (
        <div className="ProcessingFields">
          <h1 className="ProcessingFields__mainHeading"></h1>
          {sourceId === "7" ||
          sourceId === "6" ||
          sourceId === "9" ||
          sourceId === "17" ? (
            <Alert
              message={
                sourceId === "7"
                  ? ZendeskInfoMessage
                  : sourceId === "9"
                  ? FacebookInfoMessage
                  : sourceId === "17"
                  ? LeadsAPIInfoMessage
                  : GoogleDriveInfoMessage
              }
              // showIcon
              style={{
                marginBottom: "20px",
                marginLeft: "35px",
                width: "auto",
                marginRight: "35px",
                marginTop: "-25px",
              }}
            />
          ) : null}

          <form className="ProcessingFields__wrapper" onSubmit={onSubmit}>
            <>
              {processingRequiredFields.map((field, index) => (
                <div className="ProcessingFields__div" key={index}>
                  <h6 className="ProcessingFields__heading">{field.heading}</h6>
                  {field.heading === "API Version" ||
                  field.heading === "App Version" ? (
                    <select
                      className="ProcessingFields__input"
                      name={field.name}
                      value={field.value}
                      onChange={(e) => handleComponentFormChange(e, index)}
                    >
                      <option value="">Choose an API version</option>
                      {apiVersions.map((version) => (
                        <option key={version} value={version}>
                          {version}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <Input
                      className="ProcessingFields__input"
                      name={field.name}
                      value={field.value}
                      onChange={(e) => handleComponentFormChange(e, index)}
                      placeholder={field.placeholder}
                    />
                  )}
                  <p className="ProcessingFields__para">{field.description}</p>
                  {componentFormError[field.name] && (
                    <div className="ProcessingFields__error">
                      <p>{componentFormError[field.name]}</p>
                    </div>
                  )}
                </div>
              ))}
            </>
          </form>

          <div className="ProcessingFields__buttonsDiv">
            <Button
              className="ProcessingFields__button"
              type="primary"
              onClick={
                sourceId !== "7" && sourceId !== "13" && sourceId !== "9"
                  ? onSubmit
                  : handleAuthentication
              }
            >
              {sourceId !== "7" && sourceId !== "13" && sourceId !== "9"
                ? "Submit"
                : "Authenticate"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default ProcessingFields;
