import { getSelectedWorkspaceProfileId, isMobile } from "utils";
import ListofDatasets from "./ListOfDatasets";
import DatasetsDesktop from "./DatasetsListDesktop/DatasetsDesktop";
import {
  downloadDatasetLink
} from "api/Datasets";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDatasetsList } from "api/Datasets";
import { Spin } from "antd";
import { setExternalUserInfo, setUserSession } from "store/modules/auth/action";
import { getUserWorkspaceByProfileIds, joinWorkspace } from "api/Workspaces";
import { emitCustomEvent } from "react-custom-events";

const DataSetsList = () => {
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [dataListResponseData, setDataListResponseData] = useState([]);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [page, setPage] = useState(1); // Start at page 1
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [myWorkplaces, setMyWorkplaces] = useState([]);
  const [visibility, setVisibility] = useState(false);

  const dispatch = useDispatch();
  const userSession = useSelector((state) => state.authReducer.userSession);
  const authReducer = useSelector((state) => state.authReducer);
  const domainAndUserInfo = authReducer.domainAndUserInfo;
  const externalUserInfo = authReducer.externalUserInfo;
  const externalInviteInfo = authReducer.externalInviteInfo;

  const OWNER = "owner";
  const FOUNDER = "founder";
  
  const profileWSId = getSelectedWorkspaceProfileId(userSession); // Get workspace profile ID

  // Fetch Data Function
  const fetchData = useCallback(async (page) => {
    if (profileWSId > 0) {  // Ensure profileWSId is valid
      setLoader(true);
      try {
        const payload = { workspaceId: profileWSId.toString() };
        const res = await getDatasetsList(payload, page);
        setLoader(false);
        if (res.data?.data) {
          setDataListResponseData((prevData) => [...prevData, ...res.data?.data]);
          if (res.data?.data.length === 0) {
            setHasMore(false);
          }
        } else {
          console.error("Fetched data is not an array", res.data?.data);
        }
      } catch (err) {
        setLoader(false);
        console.error("Error fetching data:", err);
      }
    }
  }, [profileWSId]);

  // Initial Data Fetching Hook
  useEffect(() => {
    if (!hasRunOnce && profileWSId > 0) { // Only run when profileWSId is valid
      fetchData(page);
      setHasRunOnce(true);
    }
  }, [profileWSId, fetchData]);

  const handlePageNumber = () => {
    if (hasMore && !loader) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (page > 1) {
      fetchData(page);
    }
  }, [page, fetchData]);

  useEffect(() => {
    callGetUserWorkspaceByProfileIdsAPI();
  }, []);

  useEffect(() => {
    callUpdateUserWorkspaceByProfileIdsAPI();
  }, [userSession]);

  const callJoinWorkspaceAPI = (marketplaceId) => {
    if (marketplaceId !== undefined) {
      const payload = {
        workspace_id: marketplaceId,
        buyer_profile_id: userSession.buyerProfileId,
        seller_profile_id: userSession.sellerProfileId,
      };
      joinWorkspace(payload)
        .then((res) => {
          let updatedUserSession = { ...userSession };
          updatedUserSession.selectedWorkspaceId = parseInt(marketplaceId);
          updatedUserSession.buyerProfileWorkspaceId = res?.data?.data?.buyer_profile_workspace_id;
          updatedUserSession.sellerProfileWorkspaceId = res?.data?.data?.seller_profile_workspace_id;
          updatedUserSession.isDefaultWorkspace = false;
          updatedUserSession.workspaceCategoryId = res?.data?.data?.workspace_info?.workspace_category_id;
          userSession.selectedWorkspaceName = res?.data?.data?.workspace_info?.name;
          dispatch(setUserSession(updatedUserSession));
        })
        .catch((err) => {});
    }
  };

  const callGetUserWorkspaceByProfileIdsAPI = () => {
    const payload = {
      profileids: userSession.buyerProfileId + "," + userSession.sellerProfileId,
    };

    getUserWorkspaceByProfileIds(payload)
      .then((res) => {
        let externalWorkspaceId = domainAndUserInfo?.marketplaceId;
        if (externalInviteInfo != null) {
          externalWorkspaceId = externalInviteInfo.marketplaceId;
        } else if (externalUserInfo != null) {
          externalWorkspaceId = externalUserInfo.marketplaceId;
        }

        let marketplacesList = res?.data?.data;
        setMyWorkplaces(marketplacesList);

        if (marketplacesList != null && marketplacesList.length > 0) {
          let defaultMarketplaceId = domainAndUserInfo?.marketplaceId;

          if (parseInt(userSession.selectedWorkspaceId) > 0) {
            defaultMarketplaceId = userSession.selectedWorkspaceId;
          }
          let isMarketPlacePresent = false;
          for (let i = 0; i < marketplacesList.length; i++) {
            let el = marketplacesList[i];
            if (el.workspace_id != null && el.workspace_id === parseInt(externalWorkspaceId)) {
              userSession.isDataMenuAvialable = el?.key === "B2B" ? true : false;
              userSession.selectedWorkspaceImagUrl = el.image_uri;
              userSession.selectedWorkspaceName = el.name;
              userSession.workspaceCategoryId = el.workspace_category_id;
              userSession.selectedWorkspaceId = el.workspace_id;
              isMarketPlacePresent = true;
              if (el?.workspace_profiles.length > 1) {
                el?.workspace_profiles.forEach((workspaceProfile) => {
                  if (workspaceProfile.workspace_role === OWNER || workspaceProfile.workspace_role === FOUNDER) {
                    userSession.isMarketPlaceOwner = true;
                  } else {
                    userSession.isMarketPlaceOwner = false;
                  }
                  if (workspaceProfile.account_role === "buyer") {
                    userSession.buyerProfileWorkspaceId = workspaceProfile.profile_workspace_id;
                  } else {
                    userSession.sellerProfileWorkspaceId = workspaceProfile.profile_workspace_id;
                  }
                });
              }
              break;
            } else {
              isMarketPlacePresent = false;
            }
          }
          dispatch(setUserSession(userSession));

          if (!isMarketPlacePresent && externalWorkspaceId !== 1 && 
            (externalUserInfo?.playbookId === null || externalUserInfo?.playbookId === undefined)) {
            callJoinWorkspaceAPI(externalWorkspaceId);
            if (externalUserInfo != null) {
              dispatch(setExternalUserInfo(null));
            }
          }
        } else {
          if (externalWorkspaceId > 0 && 
            (externalUserInfo?.playbookId === null || externalUserInfo?.playbookId === undefined)) {
            callJoinWorkspaceAPI(externalWorkspaceId);
            dispatch(setExternalUserInfo(null));
          }
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const callUpdateUserWorkspaceByProfileIdsAPI = () => {
    const payload = {
      profileids: userSession.buyerProfileId + "," + userSession.sellerProfileId,
    };

    getUserWorkspaceByProfileIds(payload)
      .then((res) => {
        let marketplacesList = res?.data?.data;
        setMyWorkplaces(marketplacesList);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const switchWorkspaceClicked = (item, workspaceId, workspaceCategoryId, arrayOfWorkspaceProfiles) => {
    userSession.isDataMenuAvialable = item.key === "B2B" ? true : false;
    userSession.selectedWorkspaceImagUrl = item.image_uri;
    userSession.selectedWorkspaceName = item.name;
    userSession.selectedWorkspaceId = workspaceId;
    emitCustomEvent("selected-workspace-id-changed");
    userSession.workspaceCategoryId = workspaceCategoryId;

    if (arrayOfWorkspaceProfiles.length >= 1) {
      arrayOfWorkspaceProfiles.forEach((workspaceProfile) => {
        if (workspaceProfile.workspace_role === OWNER || workspaceProfile.workspace_role === FOUNDER) {
          userSession.isMarketPlaceOwner = true;
        } else {
          userSession.isMarketPlaceOwner = false;
        }
        if (workspaceProfile.account_role === "buyer") {
          userSession.buyerProfileWorkspaceId = workspaceProfile.profile_workspace_id;
        } else {
          userSession.sellerProfileWorkspaceId = workspaceProfile.profile_workspace_id;
        }
      });

      userSession.isDefaultWorkspace = true;
    } else {
      userSession.isDefaultWorkspace = false;
      if (arrayOfWorkspaceProfiles[0].account_role === "buyer") {
        userSession.buyerProfileWorkspaceId = arrayOfWorkspaceProfiles[0].profile_workspace_id;
      } else {
        userSession.sellerProfileWorkspaceId = arrayOfWorkspaceProfiles[0].profile_workspace_id;
      }
    }
    dispatch(setUserSession(userSession));
    setVisibility(false);
  };

  return (
    <>
      {isMobileView ? (
        <Spin spinning={isLoading}>
          <ListofDatasets datasetsListData={dataListResponseData} />
        </Spin>
      ) : (
        <DatasetsDesktop
          datasetsListData={dataListResponseData}
          loader={loader}
          handlePageNumber={handlePageNumber}
          hasMore={hasMore}
          downloadDatasetLink={downloadDatasetLink}
        />
      )}
    </>
  );
};

export default DataSetsList;
