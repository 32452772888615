import API from "..";

export const getSources = (payload) => {
  return API.get("/search/getSources", payload);
};
export const getRecordDetails = (payload) => {
  return API.get("/search/getRecordDetails", payload);
};

//People
export const entityFilterSearch = (payload) => {
  console.log("payload: ", payload);

  return API.post("/filters", payload.body, payload.params);
};

//Datasets
export const getDatasetsFilterValues = (payload) => {
  return API.get("/search/dataset/getFilterValues", payload);
};
export const searchDatasets = (payload) => {
  return API.post("/search/dataset", payload.body, payload.params);
};
export const getDatasetFiles = (payload) => {
  return API.get("/search/dataset/getDatasetFiles", payload);
};
export const downloadDatasetFiles = (payload) => {
  return API.post("/search/dataset/generateS3PreSignedUrls", payload);
};

//Company
export const getCompanyFilterValues = (payload) => {
  return API.get("/search/company/getFilterValues", payload);
};
export const searchCompany = (payload) => {
  return API.get("/dataset/companyExplorerSection", payload.params);
};

//People
export const getPeopleFilterValues = (payload) => {
  return API.get("/search/people/getFilterValues", payload);
};
export const searchPeople = (payload) => {
  return API.get("/dataset/profileExplorerSection", payload.params);
};

//Places
export const getPlacesFilterValues = (payload) => {
  return API.get("/search/place/getFilterValues", payload);
};
export const searchPlaces = (payload) => {
  return API.get("dataset/placesExplorerSection", payload.params);
};

//Events
export const getEventsFilterValues = (payload) => {
  return API.get("/search/event/getFilterValues", payload);
};
export const searchEvents = (payload) => {
  return API.get("/dataset/eventsExplorerSection", payload.params);
};
export const generateReport = (payload) => {
  return API.get("/event/generateReport", payload);
};

export const entityDataSearch = (payload) => {
  return API.get("/search", payload.params);
};
