import { useState, useEffect } from "react";
import { Collapse, Card, Drawer, Image, notification } from "antd";
import { MarkDoneEmpty, MarkedGreen } from "images";
import DesktopSelectDestination from "./DesktopSelectDestination";
import DesktopDatasetDetails from "./DesktopDatasetDetails";

const { Panel } = Collapse;

function DesktopDatasetCreation({
  handleListSelection,
  handleCreateDataSet,
  handleDestinationValue,
  destinations,
  handleDatasetSelection,
  destinationsLoader,
}) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [activeKey, setActiveKey] = useState([""]);
  const [sourceSelected, setSourceSelected] = useState("");
  const [datasetDetails, setDatasetDetails] = useState("");

  useEffect(() => {
    const allSelectedNow = sourceSelected && datasetDetails;
    // setAllSelected(allSelectedNow);
    handleDatasetSelection(allSelectedNow);
  }, [sourceSelected, datasetDetails]);

  const handleSourceSelection = (val) => {
    setSourceSelected(val);
    if (val) {
      setActiveKey((prevKeys) =>
        prevKeys.filter((key) => key !== "1").concat("2")
      );
    }
  };
  const handleDatasetDetails = (val) => {
    setDatasetDetails(val);
  };

  const CustomPanelHeader = ({ mainHeading, isSelected }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFFF", // Change background color based on isSelected
        color: "#000000",
        padding: "8px",
        borderRadius: "16px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Image
        src={isSelected ? MarkedGreen : MarkDoneEmpty}
        preview={false}
        style={{ marginRight: "8px", width: "20px", height: "20px" }}
      />
      <p
        style={{
          flex: 1,
          fontFamily: "Lato",
          fontWeight: "bold",
          fontSize: "15px",
          textAlign: "left",
          margin: 0,
        }}
      >
        {mainHeading}
      </p>
    </div>
  );

  const handlePanelChange = (key) => {
    // console.log("destinationKey", sourceSelected);
    // if (key.includes("2") && !sourceSelected) {
    //   notification.warning({
    //     message: "Destination Selection Required",
    //     description: "Please select a destination before creating the dataset.",
    //   });
    // } else {
    setActiveKey(key);
    // }
  };

  return (
    <div
      className="desktop_dataset"
      style={{ backgroundColor: "#eff2fc", padding: "20px" }}
    >
      <Collapse
        activeKey={activeKey} // Controlled active panels
        onChange={handlePanelChange}
        expandIconPosition="end"
        style={{ backgroundColor: "#eff2fc", fontSize: "15px" }}
      >
        <Panel
          header={
            <CustomPanelHeader
              mainHeading="Select your destinations"
              isSelected={sourceSelected}
            />
          }
          style={{ backgroundColor: "#eff2fc" }}
          key="1"
        >
          <DesktopSelectDestination
            handleSourceSelection={handleSourceSelection}
            handleDestinationValue={handleDestinationValue}
            destinations={destinations}
            destinationsLoader={destinationsLoader}
          />
        </Panel>
        <Panel
          header={
            <CustomPanelHeader
              mainHeading="Create Dataset"
              isSelected={datasetDetails}
            />
          }
          style={{ backgroundColor: "#eff2fc" }}
          key="2"
        >
          <DesktopDatasetDetails
            handleCreateDataSet={handleCreateDataSet}
            handleDatasetDetails={handleDatasetDetails}
          />
        </Panel>
      </Collapse>
    </div>
  );
}

export default DesktopDatasetCreation;
