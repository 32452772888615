import { message, Form, Button } from "antd";
import { searchPeople, entityDataSearch, entityFilterSearch } from "api/search";
import DataTable from "components/DataTable";
import { getIdColumn } from "components/DataTable/Columns";
import { DEFAULT_DATE_FORMAT } from "constants";
import { ENTITY_TYPES } from "constants";
import moment from "moment";
import { getSelectedWorkspaceProfileId } from "utils";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FilterFieldsContainer from "../Commons/SideFilters/Fields/FilterFieldsContainer";
import FilterDropdown, {
  FILTER_DROPDOWN_SUFFIX,
} from "../Commons/SideFilters/Fields/FilterDropdown";
import Searchbar from "../Commons/Searchbar";
import "./index.scss";
import SideFilters from "../Commons/SideFilters";
import { SHOULD_SEARCH_ON_PAGE_LOAD } from "constants";
import { isEmpty } from "lodash";
import { truncateText } from "utils";

function PeopleSearch() {
  const peopleColumns = [
    {
      title: "PERSON ID",
      dataIndex: "revcloud_person_id",
    },
    {
      title: "NAME",
      dataIndex: "name",
      // render: (text) => <a className="nameValue">{text}</a>,
    },
    {
      title: "SOURCE",
      dataIndex: "source",
    },
    {
      title: "ADDRESS",
      dataIndex: "address",
    },
    {
      title: "CITY",
      dataIndex: "city",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
    },

    // {
    //   title: "LINKEDIN URL",
    //   dataIndex: "linkedin_url",
    //   render: (text) => <p>{truncateText(text)}</p>,
    // },
    {
      title: "ACCURATE TO DATE",
      dataIndex: "last_activity",
    },
  ];

  const [isBeginingSearch, setIsBeginingSearch] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? false : true
  );
  const [searchedText, setSearchedText] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? "" : null
  );
  const [searchedFilters, setSearchedFilters] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const userSession = useSelector((state) => state.authReducer).userSession;
  const [filterOptions, setFilterOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [resizingResults, setResizingResults] = useState(false);
  const { accountId, userId } = useSelector(
    (state) => state.authReducer
  ).userSession;
  const shouldFetchResults = useRef(SHOULD_SEARCH_ON_PAGE_LOAD ? true : false);
  const [form] = Form.useForm();
  let cols = peopleColumns;

  const idClicked = (id) => {
    form.setFieldsValue({ revcloud_person_id: [id] });
    setSearchedFilters({ revcloud_person_id: [id] });
    setShowFilters(true);
  };
  useEffect(() => {
    getSearchResults();
  }, [searchedText]);

  useEffect(() => {
    setResizingResults(true);
    setTimeout(() => {
      setResizingResults(false);
    }, 100);
  }, [showFilters]);

  const getSearchResults = () => {
    const filters = {};
    Object.keys(searchedFilters).forEach((filter) => {
      let filterValue = searchedFilters[filter];

      if (moment.isMoment(filterValue)) {
        filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
      } else if (["string", "number"].includes(typeof filterValue)) {
        filterValue = [filterValue];
      } else {
        filterValue = filterValue || [];
      }

      filters[filter] = filterValue;
    });
    const payload = {
      body: {
        ...filters,
      },
      params: {
        userId,
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        page: 1,
        limit: 1000,
      },
    };

    if (searchedText === "") {
      setLoading(true);
      searchPeople(payload)
        .then((res) => {
          const formattedData = res?.data?.data?.data
            ? res.data.data.data.map((el) => {
                el.last_activity = moment(el.last_activity)
                  .utc()
                  .format(DEFAULT_DATE_FORMAT);
                return el;
              })
            : [];

          setSearchResults(formattedData);
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (searchedText !== "") {
      const searchFieldPayload = {
        params: {
          userId,
          profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
          page: 1,
          limit: 1000,
          searchTerm: searchedText,
          entityTypeId: ENTITY_TYPES.PEOPLE,
        },
      };
      setLoading(true);
      entityDataSearch(searchFieldPayload)
        .then((res) => {
          const formattedData = res?.data?.data?.data
            ? res.data.data.data.map((el) => {
                el.last_activity = moment(el.last_activity)
                  .utc()
                  .format(DEFAULT_DATE_FORMAT);
                return el;
              })
            : [];

          setSearchResults(formattedData);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onApplyFilters = (changedFields) => {
    const updatedFilters = { ...searchedFilters, ...form.getFieldsValue() };

    setSearchedFilters(updatedFilters);

    const hasDataInFilters = Object.values(updatedFilters).some(
      (value) => value && value.length > 0
    );

    if (hasDataInFilters) {
      const filters = {};
      Object.keys(updatedFilters).forEach((filter) => {
        let filterValue = updatedFilters[filter];

        if (moment.isMoment(filterValue)) {
          filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
        } else if (["string", "number"].includes(typeof filterValue)) {
          filterValue = [filterValue];
        } else {
          filterValue = filterValue || [];
        }

        filters[filter] = filterValue;
      });

      const searchPayload = {
        body: {
          filters: {
            ...filters,
            name: searchedText ? [searchedText] : [],
          },
        },
        params: {
          userId,
          profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
          entityTypeId: ENTITY_TYPES.PEOPLE,
        },
      };

      setLoading(true);
      entityFilterSearch(searchPayload)
        .then((res) => {
          const formattedData = Array.isArray(res?.data?.data)
            ? res.data.data.map((el) => {
                el.last_activity = moment(el.last_activity)
                  .utc()
                  .format(DEFAULT_DATE_FORMAT);
                return el;
              })
            : [];

          setSearchResults(formattedData);
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getSearchResults();
    }
  };

  const onResetFields = (fields) => {
    form.resetFields(fields);
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    const fieldValues = form.getFieldsValue();
    fields.forEach((field) => {
      fieldValues[field] = "";
    });
    setSearchedFilters(fieldValues);

    getSearchResults();
  };

  const filterFields = [
    <FilterFieldsContainer
      label="Source"
      onClear={() => {
        onResetFields(["source"]);
      }}
    >
      <FilterDropdown
        name="source"
        selectMode="tags"
        placeholder="Enter source name here…"
        // options={filterOptions.sources}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Person Id"
      onClear={() => {
        onResetFields(["revcloud_person_id"]);
      }}
    >
      <FilterDropdown
        name="revcloud_person_id"
        selectMode="tags"
        placeholder="Person Id…"
        // options={filterOptions.revcloud_person_id}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.ID}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="General information"
      onClear={() => {
        onResetFields(["address", "email", "phone"]);
      }}
    >
      <FilterDropdown
        name="email"
        selectMode="tags"
        placeholder="Email"
        // options={filterOptions.email}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.EMAIL}
        onChange={onApplyFilters}
      />
      <FilterDropdown
        name="phone"
        selectMode="tags"
        placeholder="Phone Number"
        // options={filterOptions.phone}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.PHONE}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
  ];
  return (
    <div className="peopleSearch">
      {/* <div className="peopleSearch__saveContainer">
        <Button className="peopleSearch__saveContainer__button">
          Save Search
        </Button>
      </div> */}
      <Searchbar
        placeholder="Search..."
        filtersVisible={showFilters}
        onFiltersVisibilityChange={setShowFilters}
        onSubmitSearchText={(searchedText) => {
          shouldFetchResults.current = true;
          setIsBeginingSearch(false);
          setSearchedText(searchedText);
        }}
        filtersApplied={
          !isEmpty(
            Object.values(searchedFilters).filter((value) => {
              return !isEmpty(value);
            })
          )
        }
      />
      <div className="peopleSearch__filterAndResults">
        <>
          {showFilters && (
            <div className="peopleSearch__filterAndResults__filters">
              <Form form={form}>
                <SideFilters
                  filterFields={filterFields}
                  onClose={() => {
                    setShowFilters(false);
                  }}
                  onReset={() => {
                    form.resetFields();
                    shouldFetchResults.current = true;
                    setIsBeginingSearch(false);
                    setSearchedFilters({});
                    getSearchResults();
                  }}
                />
              </Form>
            </div>
          )}
          {!isBeginingSearch && !resizingResults && (
            <div className="peopleSearch__filterAndResults__results">
              <DataTable
                entityTypeId={ENTITY_TYPES.PEOPLE}
                loading={loading}
                columns={peopleColumns}
                dataSource={searchResults}
                detailsKey="name"
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
}
export default PeopleSearch;
