import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { Image, Button, message, Spin, List, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import {
  IconChevronLeft,
  IconCSVFile,
  MessageMediaIcon,
  UpArrow,
} from "images";
import { useDispatch, useSelector } from "react-redux";
import {
  createCSVSource,
  deleteFile,
  getFiles,
  processFiles,
} from "api/FileOnboard";
import { getSelectedWorkspaceProfileId } from "utils";
import FileUpload from "components/FileUpload";
import InprogressUploads from "components/InprogressUploads";
import { clearFileOnboardingState } from "store/modules/fileOnboard/action";

export const FON_STAGE = {
  UNPROCESSED: "Unprocessed",
};
export const FON_STAGE_DESCRIPTION = {
  [FON_STAGE.UNPROCESSED]: "",
  [FON_STAGE.CONTINUE_BUTTON]: "",
  [FON_STAGE.PROCESSING]:
    "We are validating the data and adding to your knowledge graph.",
  [FON_STAGE.READY_TO_PROCESS]:
    "We are validating the data and adding to your knowledge graph.",
  [FON_STAGE.PROCESSED]:
    "We have validated the data and it is now searchable in the 'Datasets' section.",
  [FON_STAGE.VALIDATION_FAILED]:
    "The data has not passed the system checks. Kindly review the error message.",
  [FON_STAGE.UPLOAD_FAILED]:
    "The data has not passed the system checks. Kindly review the error message.",
  [FON_STAGE.UPLOAD_DUPLICATE]:
    "This is a duplicate file, we won't process this file.",
};
export const FON_STAGE_ICON = {
  [FON_STAGE.UNPROCESSED]: null,
};

function CSVOverviewCatalog() {
  const navigate = useNavigate();
  const [readyForStep2List, setReadyForStep2List] = useState([]);
  const [preSignedUrl, setPreSignedUrl] = useState(null);
  const [reFetchFileDetails, setReFetchFileDetails] = useState(true);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const newFileUploaded = useSelector(
    (state) => state.fileOnboardReducer
  ).newFileUploaded;
  const userSession = useSelector((state) => state.authReducer).userSession;
  // Use a ref for the signed URL to ensure it's up-to-date without re-rendering
  const preSignedUrlRef = useRef(null);

  useEffect(() => {
    if (newFileUploaded === null) {
      return;
    }
    const payload = {
      user_id: userSession.userId,
      profileworkspaceId: getSelectedWorkspaceProfileId(userSession),
    };
    const isFileUploadedRecently =
      new Date().getTime() - newFileUploaded < 3000; //Within 3 sec
    setTimeout(
      () => {
        getFiles(payload)
          .then((res) => {
            console.log("Files Data", res);
            const files = res.data.data.response || [];
            const readyForStep2Files = [];

            files.forEach((file) => {
              switch (file.status) {
                case FON_STAGE.UNPROCESSED:
                  readyForStep2Files.push(file);
                  break;
                default:
                  break;
              }
            });
            setReadyForStep2List(readyForStep2Files);
          })
          .catch((err) => {
            message.error(err.message);
          })
          .finally(() => {});
      },
      isFileUploadedRecently ? 3000 : 0 //Give time for recent file to be available in db before fetching the list
    );
  }, [newFileUploaded, reFetchFileDetails]);

  useEffect(() => {
    createSource();
  }, [readyForStep2List]);

  const createSource = () => {
    if (readyForStep2List.length > 0) {
      console.log("readyForStep2List", preSignedUrlRef.current);
      setUploading(true); // Start loader before source creation
      // const filePaths = readyForStep2List.map((item) => {
      //   return item.s3_url;
      // });
      const filePaths = [preSignedUrlRef.current]; //[preSignedUrl];
      const payload = {
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        files: filePaths,
      };

      createCSVSource(payload)
        .then(() => {
          navigate(`/dashboard/datasets/sources`);
          // const processfilesPayload = {
          //   urls: filePaths,
          // };
          // processFiles(processfilesPayload)
          //   .then(() => {
          //     // Stop loader before redirecting
          //     setUploading(false);
          //     navigate(`/dashboard/datasets/sources`);
          //   })
          //   .catch((err) => {
          //     console.log("Process files API error", err);
          //     setUploading(false); // Stop loader on error
          //   });
        })
        .catch((err) => {
          console.log("Error", err);
          setUploading(false); // Stop loader on error
        })
        .finally(() => {
          dispatch(clearFileOnboardingState(null));
          setUploading(false); // Stop loader on error
        });
    } else {
      console.log("There is no file available for source creation");
    }
  };

  const handleSavePresignedUrl = (presigned) => {
    setPreSignedUrl(presigned); // Update state
    preSignedUrlRef.current = presigned; // Update ref
  };

  return (
    <div className="CSVOverviewCatalog">
      <div className="CSVOverviewCatalog__Fixed">
        <div className="CSVOverviewCatalog__sourceText">
          <div>
            <Image
              src={IconCSVFile}
              preview={false}
              style={{ marginRight: "8px", width: "50px", height: "50px" }}
            />
            <span style={{ marginLeft: "15px" }}>CSV Onboarding</span>
          </div>
        </div>
        <div className="CSVOverviewCatalog__bar">
          <div className="CSVOverviewCatalog__leftSection">
            <FileUpload
              isMultiple={false}
              savePresignedUrl={handleSavePresignedUrl}
            >
              <Tooltip title="Upload csv file(s) from your computer.">
                <div className="processingSteps__header__rightContent__uploadBtn">
                  <div>
                    <Button className="CSVOverviewCatalog__button">
                      Add File
                    </Button>
                  </div>
                </div>
              </Tooltip>
            </FileUpload>
          </div>
        </div>

        <div className="CSVOverviewCatalog__newSource">
          <Button className="CSVOverviewCatalog__newText" disabled>
            Source
          </Button>
        </div>
      </div>
      <Spin spinning={uploading} tip="Creating your source, please wait...">
        <div>
          <div>
            <div className="CSVOverviewCatalog__mainContainer">
              <div className="CSVOverviewCatalog__flexDiv1">
                {/* <div className="CSVOverviewCatalog__container3"> */}
                <h6 className="CSVOverviewCatalog__overviewHeading1">
                  Overview
                </h6>
                <p className="CSVOverviewCatalog__overviewText">
                  Our platform allows you to upload CSV files to seamlessly
                  onboard your data. Whether your file contains information
                  about people, places, companies, or events, we use AI to
                  automatically detect and map the entity types for you. Here's
                  how it works:
                  <ul>
                    <li>
                      <strong>Automatic Entity Recognition:</strong> Our AI
                      scans your file and recognizes the type of data (e.g.,
                      names, locations, businesses, or events).
                    </li>
                    <li>
                      <strong>Smart Schema Mapping:</strong> No need to worry
                      about column names or structure! The system intelligently
                      picks and matches your data fields to our internal format.
                    </li>
                    <li>
                      <strong>Datalake Storage:</strong> Once processed, your
                      data is securely saved in our datalake, where you can
                      search, analyze, and utilize it across various features of
                      the platform.
                    </li>
                  </ul>
                  Simply upload your CSV file and let our AI handle the rest!
                </p>
              </div>
            </div>
            <InprogressUploads />
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default CSVOverviewCatalog;
