import { Button, Form, message } from "antd";
import {
  generateReport,
  getEventsFilterValues,
  getSources,
  searchEvents,
  entityDataSearch,
  entityFilterSearch,
} from "api/search";
import DataTable from "components/DataTable";
import { getSelectedWorkspaceProfileId } from "utils";
import { getIdColumn } from "components/DataTable/Columns";
import {
  DEFAULT_DATE_FORMAT,
  ENTITY_TYPES,
  SHOULD_SEARCH_ON_PAGE_LOAD,
} from "constants";
import exportFromJSON from "export-from-json";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Searchbar from "../Commons/Searchbar";
import SideFilters from "../Commons/SideFilters";
import FilterDropdown, {
  FILTER_DROPDOWN_SUFFIX,
} from "../Commons/SideFilters/Fields/FilterDropdown";
import FilterFieldsContainer from "../Commons/SideFilters/Fields/FilterFieldsContainer";
import "./index.scss";

function EventsSearch() {
  const eventsColumns = [
    {
      title: "PERSON ID",
      dataIndex: "revcloud_person_id",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      title: "SOURCE",
      dataIndex: "source",
      // render: (text) => <a className="nameValue">{text}</a>,
    },
    {
      title: "EVENT TYPE",
      dataIndex: "event_type",
    },
    {
      title: "EVENT TIMESTAMP",
      dataIndex: "event_timestamp",
    },
  ];
  const [isBeginingSearch, setIsBeginingSearch] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? false : true
  );
  const [searchedText, setSearchedText] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? "" : null
  );
  const [searchedFilters, setSearchedFilters] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const userSession = useSelector((state) => state.authReducer).userSession;
  const [resizingResults, setResizingResults] = useState(false);
  const { accountId, userId } = useSelector(
    (state) => state.authReducer
  ).userSession;
  const shouldFetchResults = useRef(SHOULD_SEARCH_ON_PAGE_LOAD ? true : false);
  const [form] = Form.useForm();
  let cols = eventsColumns;
  const [generatingReport, setGeneratingReport] = useState(false);

  const idClicked = (id) => {
    form.setFieldsValue({ revcloud_person_id: [id] });
    setSearchedFilters({ revcloud_person_id: [id] });
    setShowFilters(true);
  };

  useEffect(() => {
    if (shouldFetchResults.current) {
      getSearchResults();
    }
  }, [searchedText]);

  useEffect(() => {
    setResizingResults(true);
    setTimeout(() => {
      setResizingResults(false);
    }, 100);
  }, [showFilters]);

  const getSearchResults = () => {
    const filters = {};
    Object.keys(searchedFilters).forEach((filter) => {
      let filterValue = searchedFilters[filter];
      if (moment.isMoment(filterValue)) {
        filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
      } else if (["string", "number"].includes(typeof filterValue)) {
        filterValue = [filterValue];
      } else {
        filterValue = filterValue || [];
      }
      filters[filter] = filterValue.join(",");
    });
    const payload = {
      body: {
        ...filters,
      },
      params: {
        userId,
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        page: 1,
        limit: 1000,
      },
    };
    const searchPayload = {
      body: {
        ...filters,
      },
      params: {
        userId,
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        page: 1,
        limit: 1000,
        searchTerm: searchedText,
        entityTypeId: ENTITY_TYPES.EVENTS,
      },
    };
    if (searchedText === "") {
      setLoading(true);
      searchEvents(payload)
        .then((res) => {
          const formattedData = res?.data?.data?.data
            ? res.data.data.data.map((el) => {
                el.last_activity = moment(el.last_activity)
                  .utc()
                  .format(DEFAULT_DATE_FORMAT);
                return el;
              })
            : [];

          setSearchResults(formattedData);
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (searchedText !== "") {
      setLoading(true);
      entityDataSearch(searchPayload)
        .then((res) => {
          setSearchResults(res?.data?.data?.data);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onApplyFilters = (changedFields) => {
    const updatedFilters = { ...searchedFilters, ...form.getFieldsValue() };

    setSearchedFilters(updatedFilters);

    const hasDataInFilters = Object.values(updatedFilters).some(
      (value) => value && value.length > 0
    );

    if (hasDataInFilters) {
      const filters = {};
      Object.keys(updatedFilters).forEach((filter) => {
        let filterValue = updatedFilters[filter];

        if (moment.isMoment(filterValue)) {
          filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
        } else if (["string", "number"].includes(typeof filterValue)) {
          filterValue = [filterValue];
        } else {
          filterValue = filterValue || [];
        }

        filters[filter] = filterValue;
      });

      const searchPayload = {
        body: {
          filters: {
            ...filters,
            events: searchedText ? [searchedText.trim()] : [],
          },
        },
        params: {
          userId,
          profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
          entityTypeId: ENTITY_TYPES.EVENTS,
        },
      };

      setLoading(true);
      entityFilterSearch(searchPayload)
        .then((res) => {
          const formattedData = Array.isArray(res?.data?.data)
            ? res.data.data.map((el) => {
                el.last_activity = moment(el.last_activity)
                  .utc()
                  .format(DEFAULT_DATE_FORMAT);
                return el;
              })
            : [];

          setSearchResults(formattedData);
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getSearchResults();
    }
  };

  const onResetFields = (fields) => {
    form.resetFields(fields);
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    const fieldValues = form.getFieldsValue();
    fields.forEach((field) => {
      fieldValues[field] = "";
    });
    setSearchedFilters(fieldValues);
    getSearchResults();
  };

  const onGenerateReport = () => {
    setGeneratingReport(true);
    generateReport()
      .then((res) => {
        const data = res.data.data;
        const fileName = `${moment()
          .utc()
          .format(DEFAULT_DATE_FORMAT)}_Salesforce_Events_Report`; //TODO
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setGeneratingReport(false);
      });
  };

  const filterFields = [
    <FilterFieldsContainer
      label="Source"
      onClear={() => {
        onResetFields(["source"]);
      }}
    >
      <FilterDropdown
        name="source"
        selectMode="tags"
        placeholder="Enter source name here…"
        // options={filterOptions.sources}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Event Type"
      onClear={() => {
        onResetFields(["event_type"]);
      }}
    >
      <FilterDropdown
        name="event_type"
        selectMode="tags"
        placeholder="Enter event type"
        // options={filterOptions.event_type}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Person Id"
      onClear={() => {
        onResetFields(["revcloud_person_id"]);
      }}
    >
      <FilterDropdown
        name="revcloud_person_id"
        selectMode="tags"
        placeholder="Enter Person Id "
        // options={filterOptions.revcloud_person_id}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.ID}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="General information"
      onClear={() => {
        onResetFields(["email"]);
      }}
    >
      <FilterDropdown
        name="email"
        selectMode="tags"
        placeholder="Email"
        // options={filterOptions.email}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.EMAIL}
        onChange={onApplyFilters}
      />
      {/* <FilterDropdown
        name="campaignName"
        selectMode="tags"
        placeholder="Campaign Name "
        options={filterOptions.campaignName}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      /> */}
    </FilterFieldsContainer>,
  ];

  return (
    <div className="eventsSearch">
      {/* <div className="eventsSearch__generateReportContainer">
        <Button
          className="eventsSearch__generateReportContainer__button"
          onClick={onGenerateReport}
          loading={generatingReport}
        >
          Generate Report
        </Button>
      </div> */}
      <Searchbar
        placeholder="Search..."
        filtersVisible={showFilters}
        onFiltersVisibilityChange={setShowFilters}
        onSubmitSearchText={(searchedText) => {
          shouldFetchResults.current = true;
          setIsBeginingSearch(false);
          setSearchedText(searchedText);
        }}
        filtersApplied={
          !isEmpty(
            Object.values(searchedFilters).filter((value) => {
              return !isEmpty(value);
            })
          )
        }
      />
      <div className="eventsSearch__filterAndResults">
        <>
          {showFilters && (
            <div className="eventsSearch__filterAndResults__filters">
              <Form form={form}>
                <SideFilters
                  filterFields={filterFields}
                  onClose={() => {
                    setShowFilters(false);
                  }}
                  onReset={() => {
                    form.resetFields();
                    shouldFetchResults.current = true;
                    setIsBeginingSearch(false);
                    setSearchedFilters({});
                    getSearchResults();
                  }}
                />
              </Form>
            </div>
          )}
          {!isBeginingSearch && !resizingResults && (
            <div className="eventsSearch__filterAndResults__results">
              <DataTable
                entityTypeId={ENTITY_TYPES.EVENTS}
                loading={loading}
                columns={eventsColumns}
                dataSource={searchResults}
                detailsKey="provider_name"
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
}
export default EventsSearch;
