import React, { useEffect, useState } from "react";
import { Alert, Table, Tag, Tabs, Spin, Button, Input, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import Flex from "screens/Chat/Flex";
import { useSelector, useDispatch } from "react-redux";
import { getConnectorOverviewList } from "store/modules/connector/action";
import "./index.scss";
import CsvOverview from "../CsvOverview";
import ShareSourceModal from "./ShareSourceModal";

import { Dropdown } from "antd";
import {
  SearchOutlined,
  UserOutlined,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { getSelectedWorkspaceProfileId } from "utils";
import {
  addFilesToExistingSource,
  createCsvMapping,
  getFiles,
  processFiles,
} from "api/FileOnboard";
import { FON_STAGE } from "../Onboarding/Uploads/UnprocessedFiles";
import {
  clearFileOnboardingState,
  setCurrentProcessingGPTDetails,
} from "store/modules/fileOnboard/action";
import FileUpload from "components/FileUpload";
import InprogressUploads from "components/InprogressUploads";

function ConnectorOverview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const connectorOverviewList = useSelector(
    (state) => state?.connectorReducer?.connectorOverviewList
  );
  const loading = useSelector((state) => state?.connectorReducer?.loading);
  const { sourceId, sourceName, connectionName, connectorId, active } =
    useParams();

  // const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [preSignedUrl, setPreSignedUrl] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [csvRefresh, setCSVRefresh] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertDescription, setAlertDescription] = useState("");

  const [reFetchFileDetails, setReFetchFileDetails] = useState(true);
  const newFileUploaded = useSelector(
    (state) => state.fileOnboardReducer
  ).newFileUploaded;
  const userSession = useSelector((state) => state.authReducer).userSession;

  const navigateToModel = () => {
    navigate(
      `/dashboard/datasets/model/creation/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
    );
  };

  const handleCancel = (rowKey) => {
    setVisibleModal(false);
  };
  const handleCsvOverviewCallback = (hasError, failedFiles) => {
    if (hasError && failedFiles.length > 0) {
      setAlertMessage(failedFiles[0]?.error || "Onboarding Failed");
      setAlertDescription(failedFiles[0]?.reason || "No reason provided");
      setShowAlert(true);
    }
  };
  useEffect(() => {
    if (newFileUploaded === null) {
      return;
    }
    const payload = {
      user_id: userSession.userId,
      profileworkspaceId: getSelectedWorkspaceProfileId(userSession),
    };
    if (newFileUploaded) {
      setLoadingText("Validating file(s), please wait...");
    }
    // setLoading(true);
    const isFileUploadedRecently =
      new Date().getTime() - newFileUploaded < 3000; //Within 3 sec
    setTimeout(
      () => {
        getFiles(payload)
          .then((res) => {
            console.log("Files A", res);
            const files = res.data.data.response || [];
            const readyForStep2Files = [];

            files.forEach((file) => {
              switch (file.status) {
                case FON_STAGE.UNPROCESSED:
                  readyForStep2Files.push(file);
                  break;
                default:
                  break;
              }
            });

            if (readyForStep2Files.length > 0) {
              // const fileUrls = readyForStep2Files.map((file) => file.s3_url);
              const fileUrls = [preSignedUrl];

              const payload = {
                profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
                files: fileUrls,
                sourceId: sourceId,
                addFile: true,
              };

              addFilesToExistingSource(payload)
                .then((res) => {
                  if (res?.data?.message === "Error") {
                    setAlertMessage("Schema mismtach");
                    setAlertDescription("Please upload file with same schema");
                    setShowAlert(true);
                  }
                  const processfilesPayload = {
                    urls: fileUrls,
                  };
                  // processFiles(processfilesPayload)
                  //   .then((res) => {
                  //     console.log("API Response : processFiles", res);
                  //     setCSVRefresh(true);
                  //   })
                  //   .catch((err) => {
                  //     setAlertMessage(err.message);
                  //     setAlertDescription(err.message);
                  //     setShowAlert(true);
                  //   });
                })
                .catch((err) => {
                  setAlertMessage("Error");
                  setAlertDescription(err.message);
                  setShowAlert(true);
                })
                .finally(() => {
                  dispatch(clearFileOnboardingState(null));
                });
            }
          })
          .catch((err) => {
            message.error(err.message);
          })
          .finally(() => {});
      },
      isFileUploadedRecently ? 3000 : 0
    );
  }, [newFileUploaded, reFetchFileDetails]);

  const tableContainerStyle = {
    height: "calc(100vh - 230px)",
    overflow: "auto",
  };

  const handleRowClick = () => {
    if (active == "false") {
      navigate(
        `/dashboard/datasets/settings/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
  };

  const warningMessage = (
    <div>
      {connectionName} is disabled.
      <br />
      <a
        href="#"
        onClick={handleRowClick}
        style={{
          color: "#1790ff",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        Enable {connectionName}
      </a>{" "}
      to start receiving data again.
    </div>
  );

  const columns = [
    {
      title: "STATUS",
      dataIndex: "last_execution_status",
      key: "status",
      render: (text) => {
        const customTagStyle = {
          borderRadius: "10px", // Adjust the border-radius to make it round
          padding: "0px 5px 0px 8px", // Adjust padding for size
          fontSize: "12px", // Adjust font size
          // width: "80px",
        };

        const tagColor = text === "Successful" ? "green" : "red";

        return (
          <>
            {connectorId == 61 ? (
              <Tag color={tagColor} style={customTagStyle}>
                <strong>
                  {text === "Successful" ? "Data Fetched" : "Failed"}
                </strong>
              </Tag>
            ) : (
              <Tag color={tagColor} style={customTagStyle}>
                <strong>
                  {text === "Successful" ? "Data Flowing" : "Failed"}
                </strong>
              </Tag>
            )}
          </>
        );
      },
    },

    {
      title: "NAME",
      dataIndex: "event_type",
      key: "name",
    },
    {
      title: "STARTED",
      dataIndex: "last_execution_time",
      key: "started",
      render: (text, record) => {
        let lastExecutionDate = record.last_execution_time
          ? new Date(record.last_execution_time)
          : new Date(record.last_modified);
        // Calculate the time difference in milliseconds
        const timeDifference = Date.now() - lastExecutionDate.getTime();

        // Convert the time difference to hours
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

        // Format the last_execution_time
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(lastExecutionDate);

        let displayText;

        if (hoursDifference < 24) {
          // Display in hours
          displayText = `${hoursDifference} hours ago`;
        } else {
          // Convert to days
          const daysDifference = Math.floor(hoursDifference / 24);
          displayText = `${daysDifference} days ago`;
        }

        return (
          <div>
            {displayText}
            <br />
            <span style={{ fontSize: "12px", color: "#888" }}>
              {formattedDate}
            </span>
          </div>
        );
      },
    },

    {
      title: "DURATION",
      dataIndex: "total_execution_time",
      key: "duration",
      render: (text) => {
        if (text !== null) {
          const durationInSeconds = parseFloat(text);

          if (durationInSeconds < 60) {
            // Display in seconds
            return <span>{Math.round(durationInSeconds)}s</span>;
          } else if (durationInSeconds < 3600) {
            // Convert to minutes
            const durationInMinutes = durationInSeconds / 60;
            return <span>{Math.round(durationInMinutes)}min</span>;
          } else if (durationInSeconds < 86400) {
            // Convert to hours
            const durationInHours = durationInSeconds / 3600;
            return <span>{Math.round(durationInHours)}h</span>;
          } else {
            // Convert to days
            const durationInDays = durationInSeconds / 86400;
            return <span>{Math.round(durationInDays)}d</span>;
          }
        } else {
          // If null, display 0ms
          return <span>{0}ms</span>;
        }
      },
    },
    {
      title: "SYNCED",
      dataIndex: "last_execution_records",
      key: "synced",
    },
  ];
  const handleModelClick = (record) => {
    if (connectorId == 10) {
      // Redirect to your new component
      const modelId = record?.id;
      const modelName = record?.model_name;
      navigate(`/dashboard/datasets/model/overview/${modelId}/${modelName}`);
    }
  };
  const postgresColumns = [
    {
      title: "NAME",
      dataIndex: "model_name",
      key: "name",
    },

    {
      title: "MODELLING METHOD",
      dataIndex: "modelling_method",
      key: "modelling",
    },
    {
      title: "DESTINATIONS",
      dataIndex: "destination",
      key: "destinations",
    },
  ];

  const items = [
    connectorId == 10
      ? { key: "1", label: "Model" }
      : { key: "1", label: "Overview" },
    connectorId == 8 || connectorId == 6
      ? { key: "2", label: "Mapping" }
      : null,
    connectorId == 8 || connectorId == 6 ? { key: "3", label: "Tasks" } : null,
    { key: "4", label: "Settings" },
  ];

  const onChange = (activeKey) => {
    console.log("onChange: ", activeKey);
    if (activeKey === "2") {
      navigate(
        `/dashboard/datasets/sources/mapping/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "3") {
      navigate(
        `/dashboard/datasets/sources/tasks/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "4") {
      navigate(
        `/dashboard/datasets/settings/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
  };

  useEffect(() => {
    dispatch(getConnectorOverviewList(sourceId));
  }, [dispatch, sourceId]);

  const Toolbar = () => {
    const [isSearching, setIsSearching] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const handleSearchClick = () => {
      setIsSearching(true);
    };

    const handleInputChange = (e) => {
      const value = e.target.value;
      setSearchValue(value);
      if (value === "") {
        setIsSearching(false);
      }
    };

    const onAddFiles = () => {
      console.log("Add file clicked");
    };

    return (
      <div className="toolbar">
        {isSearching ? (
          <Input
            className="toolbar__input"
            placeholder="Search with file name"
            value={searchValue}
            onChange={handleInputChange}
            style={{ width: "200px" }}
          />
        ) : (
          <Button
            className="toolbar__button toolbar__button--search"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={handleSearchClick}
          />
        )}
        <Button
          onClick={() => setVisibleModal(true)}
          className="toolbar__button toolbar__button--share"
        >
          <UserOutlined />
          <span className="toolbar__text">Share</span>
          <DownOutlined />
        </Button>
        <FileUpload
          isMultiple={false}
          savePresignedUrl={(presigned) => setPreSignedUrl(presigned)}
        >
          <Button
            className="toolbar__button toolbar__button--add-files"
            onClick={onAddFiles}
          >
            <PlusOutlined />
            <span className="toolbar__text">Add File</span>
          </Button>
        </FileUpload>
        <div className="toolbar__separator"></div>
      </div>
    );
  };

  return (
    <div className="ConnectorOverview">
      <div className="csv-warning">
        {showAlert ? (
          <Alert
            message={alertMessage}
            description={alertDescription}
            type="warning"
            showIcon
            closable
            onClose={() => setShowAlert(false)}
          />
        ) : null}
      </div>
      <div className="ConnectorOverview__tabsWrapper">
        <div className="ConnectorOverview__tabsContainer">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          {connectorId === "8" || connectorId == 6 ? <Toolbar /> : null}
        </div>
      </div>
      {loading ? (
        <div className="loading-spinner">
          <Spin />
        </div>
      ) : connectorId === "8" || connectorId == 6 ? (
        <div>
          <CsvOverview
            csvRefresh={csvRefresh}
            onStatusChange={handleCsvOverviewCallback}
          />
          <InprogressUploads />
        </div>
      ) : (
        <div className="ConnectorOverview__content">
          <div
            className="ConnectorOverview__tableDiv"
            style={tableContainerStyle}
          >
            {active == "false" && (
              <Alert
                message={warningMessage}
                type="warning"
                showIcon
                style={{ marginBottom: "16px" }}
              />
            )}
            {connectorId == 10 ? (
              <div className="ConnectorOverview__parentButton">
                <Button
                  onClick={() => navigateToModel()}
                  className="ConnectorOverview__button"
                >
                  Add Model
                </Button>
              </div>
            ) : null}
            <Table
              columns={connectorId == 10 ? postgresColumns : columns}
              dataSource={connectorOverviewList?.reverse()}
              pagination={true}
              onRow={(record) => ({
                onClick: () => handleModelClick(record),
              })}
              rowClassName="hover-pointer"
            />
          </div>
        </div>
      )}
      <ShareSourceModal
        visible={visibleModal}
        connectionName={connectionName}
        onCancel={() => handleCancel()}
      />
    </div>
  );
}

export default ConnectorOverview;
